import React from "react"

export default function BGAlert ({type, message = ''}) {
    const alertClass = (type) => {
        let c = "alert"
        switch (type) {
            case 'success':
                c += " alert-success"
                break
            case 'error':
                c += " alert-danger"
                break
            default:
                c += " alert-info"
                break;

        }

        return c
    }
    return (
        <div>
            <div className={alertClass(type)} role="alert">
                {message}
            </div>
        </div>
    )
}
